<ion-page>
    <page-header :loadTrigger='loadTrigger'></page-header>
    <ion-content>
        <ion-row v-if="licenseKey">
            <ion-col size-md="3" size-xs="12">
                <label v-html="labels.license"></label>
            </ion-col>
            <ion-col size-md="9" size-xs="12">
                <label v-html="licenseKey" ></label>                
            </ion-col>        
        </ion-row>    

        <ion-row v-if="license && license.endDate">
            <ion-col size-md="3" size-xs="6">
                <label v-html="labels.endDate"></label>        
            </ion-col>
            <ion-col size-md="3" size-xs="6" v-html="formatDate(license.endDate)"></ion-col>
        </ion-row>
        <ion-row>
            <ion-col size-md="3" size-xs="6">
                <label v-html="labels.electroTemplate"></label>
            </ion-col>
            <ion-col size-md="9" size-xs="6">
                <select class="form-control" v-model="aspectTemplate" @change="save">
                    <option v-for="template in aspectTemplates" :value="template.id" v-html="template.name"></option>
                </select>        
            </ion-col>
        </ion-row>    
        <ion-row v-if="this.license != null && this.license.maxConcurrentEvents > 0">
            <ion-col size-md="3" size-xs="6">
                <label v-html="labels.maxConcurrentEvents"></label>        
            </ion-col>
            <ion-col size-md="9" size-xs="6">        
                <span v-html="this.license.maxConcurrentEvents"></span>
            </ion-col>                
        </ion-row>    
        <ion-row v-if="this.license != null && this.license.maxConcurrentEvents > 0">
            <ion-col size-md="3" size-xs="6">
                <label v-html="labels.showHistoryButton"></label>        
            </ion-col>
            <ion-col size-md="9" size-xs="6">        
                <ion-toggle v-model="showHistoryButton" @ionChange="save"></ion-toggle>                
            </ion-col>                
        </ion-row>    
        <ion-row>
            <ion-col size-md="3" size-xs="6">
                <label v-html="labels.darkMode"></label>        
            </ion-col>
            <ion-col size-md="9" size-xs="6">        
                <ion-toggle v-model="darkMode" @ionChange="save"></ion-toggle>                
            </ion-col>                
        </ion-row>                
        <ion-row v-if="canShowLuckFiguresOnProfileScreen">
            <ion-col size-md="3" size-xs="6">
                <label v-html="labels.showLuckFiguresOnProfileScreen"></label>        
            </ion-col>
            <ion-col size-md="9" size-xs="6">        
                <ion-toggle v-model="showLuckFiguresOnProfileScreen" @ionChange="save"></ion-toggle>                
            </ion-col>                
        </ion-row>    
        <ion-row>                                
            <ion-col size-md="3" size-xs="6" v-html="labels.importExportEvents"></ion-col>
            <ion-col size-md="9" size-xs="6">                           
                <icon-button class="button" :title="labels.import"  @click="chooseImport" icon="arrowupoutline"></icon-button>                    
                <file-upload class='hidden' @onChange="onImportFile" accept=".enc" icon="arrowupoutline" ref="fileUpload"></file-upload>
                <icon-button class="button" :title="labels.export"  @click="onExport" icon="arrowdownoutline"></icon-button>                    
            </ion-col>
        </ion-row>        
    </ion-content>
</ion-page>