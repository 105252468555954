import { IonPage, IonContent, IonInput, IonRow, IonCol, IonIcon, IonToggle} from '@ionic/vue';
import PageHeader from '@/components/page-header';
import captcha from '@/components/captcha';
import {LicenseSettingsVM} from '@/models/license';
import router from '@/router';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import {StorageEnum} from '@/constants/enums';
//import Constants from '@/constants/constants';
import FileUpload from '@/components/file-upload';
import IconButton from '@/components/icon-button';
import { addIcons } from 'ionicons';
import { save, arrowDownOutline as arrowdownoutline, arrowUpOutline as arrowupoutline} from 'ionicons/icons';

export default {
    components: {
        IonPage, IonContent, IonInput, IonRow, IonCol, IonIcon, IonToggle,
        PageHeader, FileUpload, IconButton, captcha
    },
    setup() {
        addIcons({
            save,
            arrowdownoutline,
            arrowupoutline,
        });
    },
    data() {
        return {
            labels:{},
            aspectTemplates: [], //AspectTemplate[],
            aspectTemplate: null, // number,            
            //licenseEmail: '',
            licenseKey: "",
            oldLicenseKey: "",
            authenticationKey: "",
            oldAuthenticationKey: "",
            darkMode: false,
            storeUserProfilesInDb: true,
            authenticationCode: "",
            license: null, //LicenseVM = null,
            licenseCreateResultVM: /*LicenseCreateResultVM = */null,
            showHistoryButton: false,
            sharingCode: null,
            showLuckFiguresOnProfileScreen: false,
            loadTrigger:0
        }
    },
    inject: ["storageService", "languageService", "importService", "exportService", "licenseService", "dialogService",
        "transportService", "settingsService", "aspectTemplateService", "utilService"],
    mounted() {
        this.languageService.getText(EnumResourceStrings.Settings).then(x => this.labels.title = x);
        this.languageService.getText(EnumResourceStrings.License).then(x => this.labels.license = x);
        this.languageService.getText(EnumResourceStrings.MaxFutureEvents).then(x => this.labels.maxFutureEvents = x);
        this.languageService.getText(EnumResourceStrings.MaxConcurrentEvents).then(x => this.labels.maxConcurrentEvents = x);
        this.languageService.getText(EnumResourceStrings.EndDate).then(x => this.labels.endDate = x);
        this.languageService.getText(EnumResourceStrings.AspectTemplate).then(x => this.labels.electroTemplate = x);
        //this.languageService.getText(EnumResourceStrings.MfaCode).then(x => this.strMfaCode = x);
        this.languageService.getText(EnumResourceStrings.OK).then(x => this.labels.OK = x);
        this.languageService.getText(EnumResourceStrings.Save).then(x => this.labels.save = x);
        this.languageService.getText(EnumResourceStrings.EmailAddress).then(x => this.labels.email = x);
        this.languageService.getText(EnumResourceStrings.ShowHistoryButton).then(x => this.labels.showHistoryButton = x);
        this.languageService.getText(EnumResourceStrings.DarkMode).then(x => this.labels.darkMode = x);
        this.languageService.getText(EnumResourceStrings.Import).then(x => this.labels.import = x);
        this.languageService.getText(EnumResourceStrings.Export).then(x => this.labels.export = x);
        this.languageService.getText(EnumResourceStrings.ImportExportEvents).then(x => this.labels.importExportEvents = x);
        this.languageService.getText(EnumResourceStrings.ExportToFileOrDevice).then(x => this.labels.exportToFileOrDevice = x);
        this.languageService.getText(EnumResourceStrings.ImportFromFileOrDevice).then(x => this.labels.importFromFileOrDevice = x);
        this.languageService.getText(EnumResourceStrings.File).then(x => this.labels.file = x);
        this.languageService.getText(EnumResourceStrings.Device).then(x => this.labels.device = x);
        this.languageService.getText(EnumResourceStrings.ImportExportMessage).then(x => this.labels.importExportMessage = x);
        this.languageService.getText(EnumResourceStrings.NoExportFound).then(x => this.labels.noExportFound = x);
        this.languageService.getText(EnumResourceStrings.ShowLuckFiguresOnProfileScreen).then(x => this.labels.showLuckFiguresOnProfileScreen = x);
        //this.languageService.getText(EnumResourceStrings.StoreUserProfilesInDb).then(x => this.strStoreUserProfilesInDb = x);
        this.importService.onImportCompleted = this.onImportCompleted;
    },
    ionViewDidEnter() {
        // Fired when the component routing to has finished animating.        
        if (this.$route.path != '/tabs/settings') {
            this.storageService.isLoggingOut = false;
            router.replace('/tabs/settings');
        } else {
            this.load();
        }

    },
    computed: {
        canShowLuckFiguresOnProfileScreen: function () {
            console.log('canShowLuckFiguresOnProfileScreen', this.license);
            return this.license && this.license.propertiesVm && this.license.propertiesVm.canShowLuckFiguresOnProfileScreen;
        }
    },
    methods: {
        load: function () {
            //this.$refs.pageHeader.init();
            this.loadTrigger++;
            var promises = [];
            promises.push(this.storageService.get(StorageEnum.AspectTemplate).then(template => {
                this.aspectTemplate = template;
            }));
            promises.push(this.storageService.get(StorageEnum.LicenseKey).then(x => {
                this.licenseKey = x ? x : '';
                this.oldLicenseKey = this.licenseKey;
            }));
            this.storageService.get(StorageEnum.DarkMode).then(x => {
                this.darkMode = x ? x : false;
                this.utilService.toggleDarkTheme(this.darkMode);
            });
            promises.push(this.storageService.get(StorageEnum.AuthenticationKey).then(x => {
                this.authenticationKey = x ? x : '';
                this.oldAuthenticationKey = this.authenticationKey;
            }));
            promises.push(this.storageService.get(StorageEnum.ShowHistoryButton).then(x => {
                this.showHistoryButton = x ? x : false;
            }));
            return Promise.all(promises).then(() => {
                return this.loadLicense(true).then(() => {
//                    this.loadTimeUnits();
                    return this.storageService.get(StorageEnum.ShowLuckFiguresOnProfileScreen).then(x => {
                        this.showLuckFiguresOnProfileScreen =
                                (x ? x : false)
                                && this.license
                                && this.license.propertiesVm
                                && this.license.propertiesVm.canShowLuckFiguresOnProfileScreen;
                    });
                });
            });
        },
        formatDate: function (value) {
            return this.utilService.formatDate(value, 'MM/DD/YYYY');
        },
        loadLicense: function (loadAlways) {
            var promises = [];
            if (loadAlways || this.licenseKey != this.oldLicenseKey || this.authenticationKey != this.oldAuthenticationKey) {
                this.licenseService.clear();
                promises.push(this.licenseService.getLicense().then(result => {
                    this.license = result.license;
                    //console.log('license', this.license);
                    if (!result || !result.succeeded) {
                        this.authenticationKey = null;
                    } else {
                        this.oldAuthenticationKey = this.authenticationKey;
                    }
                    if (this.license) {
                        return this.getAspectTemplates(this.license.aspectTemplateIds);
                    }
                }));
            }
            return Promise.all(promises);
        },

        checkIsNumber: function (event) {
            var src = event.target;
            var max = Number(src.attributes['data-max'].value);
            var number = Number(src.value);
            if (isNaN(number)) {
                src.value = '';
            } else if (max > 0 && number > max) {
                src.value = '';
            }
        },
        getAspectTemplates: function (aspectTemplateIds) {
            return this.aspectTemplateService.getAspectTemplates().then(x => {
                this.aspectTemplates = aspectTemplateIds.map(aspectTemplateId => x.find(y => y.id == aspectTemplateId)).filter(exists => exists);
                if (this.aspectTemplate == null || aspectTemplateIds.indexOf(this.aspectTemplate) < 0) {
                    this.aspectTemplate = this.aspectTemplates[0].id;
                    return this.storageService.set(StorageEnum.AspectTemplate, this.aspectTemplate);
                }
            });
        },
        setAuthenticationKey: function (authenticationKey) {
            this.authenticationKey = authenticationKey;
            this.authenticationCode = "";
            //this.showMfaCode = false;
            return this.storageService.set(StorageEnum.AuthenticationKey, this.authenticationKey);
        },
        save: function () {
            return this.loadLicense(false).then(() => {
                return this.store().then(() => {
                    return this.settingsService.save();
                });
            });
        },
        store: function () {
            var promises = [];
            var licenseSettingsVM = new LicenseSettingsVM(
                    this.licenseKey,
                    this.authenticationKey,
                    null,
                    null,
                    this.showHistoryButton,
                    this.aspectTemplate
                    );
            promises.push(this.storageService.storeLicense(licenseSettingsVM));            
            promises.push(this.storageService.set(StorageEnum.DarkMode, this.darkMode).then(() => this.utilService.toggleDarkTheme(this.darkMode)));
            promises.push(this.storageService.set(StorageEnum.ShowLuckFiguresOnProfileScreen, this.showLuckFiguresOnProfileScreen));
            this.aspectTemplateService.clearCurrentAspectTemplate();
            return Promise.all(promises);
        },
        cleanupLicenseKey: function () {
//insert dash at 8-4-4-4-12
            var result = this.utilService.cleanupLicenseKey(this.licenseKey);
            if (this.licenseKey != result) {
                this.licenseKey = result;
            }
        },

        onExport: function () {
            if (this.licenseKey) {
                this.transportService.getSharingCode().then(sharingCode => {
                    if (sharingCode) {
                        this.chooseExport();
                    } else {
                        this.exportToFile();
                    }
                });
            } else {
                this.exportToFile();
            }
        },
        chooseExport: function () {
            this.dialogService.presentChoice(this.labels.exportToFileOrDevice, this.labels.device, this.labels.file, this.exportToDevice, this.exportToFile);
        },
        exportToDevice: function () {
            return this.transportService.getSharingCode().then(sharingCode => {
                return this.exportService.getExportJson().then(json => {
                    return this.transportService.export(sharingCode, JSON.stringify(json));
                });
            });
        },
        exportToFile: function () {
            this.exportService.exportLocationCombinations();
        },
        chooseImport: function () {
            if (this.licenseKey) {
                this.dialogService.presentChoice(this.labels.importFromFileOrDevice, this.labels.device, this.labels.file, this.importFromDevice, this.importFromFile);
            } else {
                this.importFromFile();
            }
        },
        onImportFile: function (contents, skipDecrypt) {
            if (contents) {
                this.importService.importLocationCombinations(contents, skipDecrypt);
            }
        },

        importFromDevice: function () {
            //get randomcode from server which will be used to authenticate import from one device to the other
            //show alert showing this code
            //in other device this code needs to be entered to authenticate the import
            this.transportService.createSharingCode().then(sharingCode => {
                console.log('sharingCode', sharingCode);
                this.sharingCode = sharingCode;
                //var message = 'Export from other device/browser using this code ' + sharingCode + '. After doing so close this dialog';                
                this.dialogService.alert(this.labels.importExportMessage, this.importFromDeviceStep2);
            });

        },
        importFromDeviceStep2: function () {
            this.transportService.import(this.sharingCode).then(contents => {
                if (contents) {
                    this.onImportFile([contents], true);
                    this.sharingCode = null;
                } else {
                    this.dialogService.alert(this.labels.noExportFound);
                }

            });
        },
        importFromFile: function () {
            this.$refs.fileUpload.click();
        },
        onImportCompleted: function () {

        },

    }

}
