import IconButton from '@/components/icon-button';
export default {
    props: {
        accept: String,
        //title: String,
        icon: null,
        cssclass: String
    },
    components: {
        IconButton
    },
//    data() {
//        return {
//            accept,
//            title,
//            icon
//        }
//    },
    computed: {
        cssClass: function () {
            var result = 'button';
            if (this.cssclass) {
                result += " " + this.cssclass;
            }
            return result;
        }
    },
    mounted() {
        if (!this.accept)
        {
            this.accept = '*';
        }
        if (!this.title)
        {
            this.title = 'upload';
        }
        if (!this.icon)
        {
            this.icon = 'arrow-up-outline';
        }

    },
    emits: ['onChange'],
    methods: {
        click() {
            this.$refs.file.click();
        },
        onFileSelected(event) {
            var promises = [];
            for (var i = 0; i < event.target.files.length; i++) {
                promises.push(new Promise((resolve/*, reject*/) => {
                    var reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    }
                    reader.readAsText(event.target.files[i]);
                }));
            }
            Promise.all(promises).then(p => {
                this.$emit('onChange', p);
            });
        }
    }


}
